var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import EventBus from "./events/EventBus";
export function pluralize(count, one, two, five, placeholder) {
    if (five === void 0) { five = two; }
    if (placeholder === void 0) { placeholder = '%%'; }
    var number = count % 100;
    var declension = one;
    if (number > 4 && number <= 20) {
        declension = five;
    }
    else {
        number = number % 10;
        if (number >= 5 || number === 0) {
            declension = five;
        }
        else if (number >= 2 && number <= 4) {
            declension = two;
        }
    }
    if (declension.match(placeholder)) {
        return declension.replace(placeholder, count.toString());
    }
    return declension;
}
export function format(number) {
    if (!number) {
        return '0';
    }
    number = number.toString();
    if (number.match(/\d{4}/)) {
        return format(number.replace(/(\d)(\d{3})\b/, '$1 $2'));
    }
    return number;
}
export function addGlobalLiveEventListener(events, elementSelector, callback) {
    addLiveEventListener(document.documentElement, events, elementSelector, callback);
}
export function addLiveEventListener(element, events, elementSelector, callback) {
    var e_1, _a;
    elementSelector = typeof elementSelector === 'string' ? [elementSelector] : elementSelector;
    try {
        for (var _b = __values(events.split(' ')), _c = _b.next(); !_c.done; _c = _b.next()) {
            var eventType = _c.value;
            element.addEventListener(eventType, function (event) {
                var e_2, _a;
                if (!(event.target instanceof HTMLElement || event.target instanceof SVGElement)) {
                    return;
                }
                try {
                    for (var elementSelector_1 = __values(elementSelector), elementSelector_1_1 = elementSelector_1.next(); !elementSelector_1_1.done; elementSelector_1_1 = elementSelector_1.next()) {
                        var selector = elementSelector_1_1.value;
                        var el = event.target.closest(selector);
                        if (el) {
                            Object.defineProperty(event, 'target', { value: el });
                            return callback.call(el, event);
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (elementSelector_1_1 && !elementSelector_1_1.done && (_a = elementSelector_1.return)) _a.call(elementSelector_1);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            });
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
}
/**
 * This allows us to dispatch browser events in old IE and newer browsers
 */
export function triggerEvent(elem, eventName, data) {
    if (data === void 0) { data = null; }
    var event = data ? new CustomEvent(eventName, { detail: data }) : new CustomEvent(eventName);
    elem.dispatchEvent(event);
    return event;
}
export function join(arr, separator) {
    if (separator === void 0) { separator = ' '; }
    return arr.filter(function (n) { return n; }).join(separator);
}
export function escapeRegex(string) {
    return string.replace(/[\/\\^$*+?.()|[\]{}]/g, '\\$&');
}
export var isMobile = window.matchMedia('(pointer: coarse)').matches && window.innerWidth <= 768 && 'ontouchstart' in window;
export function getFetchOptions(body, credentials, method) {
    if (credentials === void 0) { credentials = false; }
    if (method === void 0) { method = 'POST'; }
    return __assign({ method: method, headers: {
            'Content-Type': 'application/json',
        }, credentials: credentials ? 'same-origin' : 'omit' }, (body ? { body: JSON.stringify(body) } : {}));
}
export function fixInjectedInlineScripts(element) {
    var e_3, _a;
    try {
        for (var _b = __values(element.querySelectorAll('script')), _c = _b.next(); !_c.done; _c = _b.next()) {
            var script = _c.value;
            var newScript = document.createElement('script');
            newScript.text = script.innerHTML;
            script.parentElement.append(newScript);
            script.remove();
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_3) throw e_3.error; }
    }
}
export function isHidden(el) {
    return (el.offsetParent === null);
}
export function onModalOpen(modalId, callback) {
    EventBus.on('modal:open', function (modal) {
        if (!modal || modal.id !== modalId) {
            return;
        }
        callback(modal);
    });
}
export function onTransitionEnd(el, callback, property) {
    if (property === void 0) { property = 'transform'; }
    function listener(e) {
        if (e.target !== el || (property && e.propertyName !== property)) {
            return;
        }
        el.removeEventListener('transitionend', listener);
        callback();
    }
    el.addEventListener('transitionend', listener);
}
export function emulateReadonly(element) {
    element.style.caretColor = 'transparent';
    element.addEventListener('keydown', onkeypress);
    element.addEventListener('paste', onkeypress);
    function onkeypress(event) {
        if (event.key.length === 1 || ['Backspace', 'Delete'].includes(event.key)) {
            event.preventDefault();
        }
    }
}
export function clearDataset(el) {
    Object.keys(el.dataset).forEach(function (key) { return delete el.dataset[key]; });
}
export function extractAppData(el) {
    return extractData(el.querySelector('script[type="application/json"]'));
}
export function extractData(el) {
    return el ? JSON.parse(el.textContent) : null;
}
export function startIconLoading(el) {
    el.querySelector('.icon').classList.add('icon--loading');
}
export function stopIconLoading(el) {
    el.querySelector('.icon').classList.remove('icon--loading');
}
export function isFullyVisible(element, clippingElement, checkViewport) {
    if (clippingElement === void 0) { clippingElement = null; }
    if (checkViewport === void 0) { checkViewport = true; }
    var rect = element.getBoundingClientRect();
    if (!clippingElement || checkViewport) {
        // Check viewport visibility as before
        var windowWidth = window.innerWidth;
        var windowHeight = window.innerHeight;
        var isViewportVisible = (rect.top >= 0 && rect.left >= 0 && rect.bottom <= windowHeight && rect.right <= windowWidth);
        // If no clipping element provided, return viewport visibility
        if (!isViewportVisible || !clippingElement) {
            return isViewportVisible;
        }
    }
    // Get clipping element's rect
    var clippingRect = clippingElement.getBoundingClientRect();
    return rect.top >= clippingRect.top && rect.left >= clippingRect.left
        && rect.bottom <= clippingRect.bottom && rect.right <= clippingRect.right;
}
export function onScrollEnd(callable, once) {
    if (once === void 0) { once = true; }
    document.addEventListener('scroll', function () {
        var lastScrollPosition = window.scrollY;
        var interval = setInterval(function () {
            if (lastScrollPosition !== window.scrollY) {
                lastScrollPosition = window.scrollY;
                return;
            }
            clearInterval(interval);
            callable();
            if (!once) {
                onScrollEnd(callable, once);
            }
        }, 20);
    }, { passive: true, once: true });
}
export function toggleSiblingsClasses(element, className, siblingSelector, parent) {
    if (siblingSelector === void 0) { siblingSelector = ':scope > *'; }
    if (parent === void 0) { parent = null; }
    parent = typeof parent === 'string' ? element.closest(parent) : parent !== null && parent !== void 0 ? parent : element.parentElement;
    parent.querySelectorAll(siblingSelector).forEach(function (el) { return el.classList.remove(className); });
    element.classList.add(className);
}
var webpSupported = false;
export function isWebpSupported() {
    return webpSupported;
}
function testWebPSupport() {
    var webP = new Image();
    webP.src = 'data:image/webp;base64,UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAABBxAR/Q9ERP8DAABWUDggGAAAADABAJ0BKgEAAQADADQlpAADcAD++/1QAA==';
    webP.onload = webP.onerror = function () { return webpSupported = webP.height === 1; };
}
testWebPSupport();
