import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["width", "height"];
var _hoisted_2 = ["href"];
export default /*@__PURE__*/ _defineComponent({
    __name: 'Sprite',
    props: {
        name: { type: String, required: true },
        size: { type: [Number, String], default: 16 },
        width: Number,
        height: Number,
        clear: Boolean,
    },
    setup: function (__props) {
        return function (_ctx, _cache) {
            var _a, _b, _c, _d;
            return (_openBlock(), _createElementBlock("svg", {
                class: _normalizeClass(__props.clear ? 'svg-clr' : 'svg'),
                width: (_a = __props.width) !== null && _a !== void 0 ? _a : __props.size,
                height: (_c = (_b = __props.height) !== null && _b !== void 0 ? _b : __props.width) !== null && _c !== void 0 ? _c : __props.size
            }, [
                _createElementVNode("use", {
                    href: "/img/sprite".concat(__props.clear ? '-clr' : '', ".svg#").concat(__props.name, "-").concat((_d = __props.width) !== null && _d !== void 0 ? _d : __props.size)
                }, null, 8 /* PROPS */, _hoisted_2)
            ], 10 /* CLASS, PROPS */, _hoisted_1));
        };
    }
});
