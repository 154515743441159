export var StatusClassNames;
(function (StatusClassNames) {
    StatusClassNames["hidden"] = "hidden";
    StatusClassNames["hide"] = "is-hide";
    StatusClassNames["open"] = "is-open";
    StatusClassNames["dragging"] = "is-dragging";
    StatusClassNames["disabled"] = "is-disabled";
    StatusClassNames["active"] = "is-active";
    StatusClassNames["filled"] = "is-filled";
    StatusClassNames["fixed"] = "is-fixed";
    StatusClassNames["ready"] = "is-ready";
    StatusClassNames["closing"] = "is-closing";
    StatusClassNames["pressed"] = "is-pressed";
    StatusClassNames["noscroll"] = "noscroll";
    StatusClassNames["show"] = "is-show";
})(StatusClassNames || (StatusClassNames = {}));
